<template>
  <div>
    <!-- Navigation  -->
    <h3
      v-if="checkItemAccess(['Dev'])"
      class="text-white w-100 text-left ml-3 mb-4"
    >
      <span
        class="px-4 cursor-pointer"
        style="border-right: 2px solid white"
        :style="isSelected('DETALHES') ? { color: cor2 } : {}"
        @click="selected = 'DETALHES'"
      >
        DETALHES
      </span>

      <span
        class="px-4 cursor-pointer"
        :style="isSelected('DADOS') ? { color: cor2 } : {}"
        @click="selected = 'DADOS'"
      >
        DADOS
      </span>
    </h3>

    <!-- Header  -->
    <div class="px-3">
      <vs-card>
        <div class="d-flex align-items-center">
          <div class="mr-2">
            <img
              :src="imgpath(projectID)"
              width="100"
            >
          </div>

          <div class="d-flex">
            <h5 class="mb-0 font-16 font-medium">
              {{ surveyName + " (" + surveyId + ")" }}
            </h5>

            <span class="pl-3">/</span>

            <div
              v-if="surveyRef != '0' && surveyRef != null && surveyRef != surveyId"
              class="d-flex"
            >
              <img
                src="https://config.fanbase.com.br/prj/0/icon-clip.png"
                class="ml-3"
                width="16"
                height="16"
              >
              <h5 class="ml-2">
                {{ "(" + surveyRef + ")" }}
              </h5>

              <span class="pl-3">-</span>
            </div>
          </div>

          <vs-chip
            v-if="phaseLoaded"
            :key="phase"
            class="ml-3"
            :color="phase === 'TEST' ? 'orange' : 'green'"
          >
            <span v-if="phase === 'TEST'">Fase de testes</span>
            <span v-else>Publicado</span>
          </vs-chip>

          <div class="ml-auto d-flex align-items-center">
            <vs-button
              v-if="checkItemAccess('Admin')"
              class="mr-2"
              color="primary"
              @click="openParamEditor"
            >
              Editar Parâmetros
            </vs-button>

            <vs-button
              v-if="phase === 'TEST' && checkItemAccess('Admin')"
              color="primary"
              @click="publishSurveyPopup = true"
            >
              Publicar Survey
            </vs-button>
          </div>
        </div>
      </vs-card>
    </div>

    <!-- Content -->
    <div
      v-if="selected === 'DADOS'"
      class="px-3"
    >
      <SurveyResult
        :survey-id="surveyId"
        :survey-name="surveyName"
      />
    </div>
    <div v-if="selected === 'DETALHES'">
      <vs-row
        vs-justify="center"
        class="px-3"
      >
        <vs-col vs-lg="12">
          <vs-row>
            <vs-col vs-lg="12">
              <vs-card>
                <TotalProjects
                  :key="qtTotal"
                  :qt-total="qtTotal"
                  :total-id-created="totalIdCreated"
                  :qt-concluded="qtConcluded"
                  :perc-concluded="percConc"
                  :series-acc="seriesAcc"
                />
              </vs-card>
            </vs-col>
          </vs-row>
        </vs-col>

        <vs-col
          v-if="histMonth[0].data.length"
          vs-lg="12"
        >
          <vs-card class="card-no-shadow">
            <h3 class="card-title">
              Pesquisas
            </h3>

            <h5 class="card-subtitle font-weight-normal text-muted">
              Iniciadas no Período
            </h5>

            <ProjHist
              :series-month="histMonth"
              :custom-icon-href="chartCSV"
            />
          </vs-card>
        </vs-col>

        <vs-col vs-lg="12">
          <vs-card>
            <div class="d-flex">
              <div class="p-2">
                <h3 class="card-title">
                  Questionário
                </h3>

                <h5 class="mb-0 card-subtitle text-muted font-weight-normal">
                  Visão geral
                </h5>
              </div>

              <div class="ml-auto p-2">
                <vs-button
                  class="btn btn bg-transparent border-0 btn btn-primary text-light"
                  :disabled="!Object.keys($store.state.surveyStruct).length"
                  @click="printSurveyPopup = true"
                >
                  <i class="fas fa-file mr-2" />

                  Visualizar / Imprimir
                </vs-button>

                <vs-button
                  v-if="checkItemAccess('Editar Projetos Digitais')"
                  class="ml-2 btn btn bg-transparent border-0 btn btn-primary text-light"
                  :disabled="!Object.keys($store.state.surveyStruct).length"
                  @click="viewJsonPopup = true"
                >
                  <i class="fas fa-eye mr-1" />

                  Ver código
                </vs-button>

                <vs-button
                  v-if="checkItemAccess('Editar Projetos Digitais')"
                  class="ml-2 btn btn bg-transparent border-0 btn btn-primary text-light"
                  :disabled="!Object.keys($store.state.surveyStruct).length"
                  @click="saveSurvey"
                >
                  <i class="fas fa-save mr-2" />

                  Salvar Alterações
                </vs-button>
              </div>
            </div>

            <SurveyStructure
              v-if="surveyId != 0"
              :survey-id="surveyId"
            />
          </vs-card>
        </vs-col>
      </vs-row>
    </div>

    <!-- Popups -->
    <!-- Edit Survey Params Popup -->
    <vs-popup
      title="Editar Parâmetros"
      :active.sync="editParamsPopup"
    >
      <vs-table :data="paramsTableData">
        <template slot="thead">
          <vs-th>
            <label class="small font-weight-bold mt-2">
              Parâmetro:
            </label>
          </vs-th>

          <vs-th>
            <label class="small font-weight-bold mt-2">
              Valor:
            </label>
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr
            v-for="(tr, indextr) in data"
            :key="indextr"
          >
            <vs-td
              :data="tr.param"
              class="params-table-td"
            >
              {{ tr.param.param }}
            </vs-td>

            <vs-td
              :data="tr.value"
              class="params-table-td"
            >
              {{ tr.value }}

              <template slot="edit">
                <vs-input
                  v-model="tr.value"
                  class="inputx"
                  @change="() => setSurveyParams(tr.param.param, tr.value)"
                />
              </template>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>

    <!-- Publish Survey Popup -->
    <vs-popup
      title="Publicar Survey"
      :active.sync="publishSurveyPopup"
    >
      <div class="text-center">
        <p>
          Você está prestes a publicar este survey. Essa ação é
          irreversível. Deseja confirmar a ação?
        </p>

        <vs-button
          color="red"
          class="mx-auto"
          @click="setSurveyPhase"
        >
          Confirmar
        </vs-button>
      </div>
    </vs-popup>

    <!-- Print Survey Popup -->
    <vs-popup
      fullscreen
      title="Visualizar / Imprimir"
      :active.sync="printSurveyPopup"
      :disabled="!Object.keys($store.state.surveyStruct).length"
    >
      <print-survey
        v-if="printSurveyPopup"
        :survey="$store.state.surveyStruct"
      />
    </vs-popup>

    <!-- View Survey Popup -->
    <vs-popup
      fullscreen
      title="Survey Viewer"
      :active.sync="viewJsonPopup"
      :disabled="!Object.keys($store.state.surveyStruct).length"
    >
      <vs-button
        class="mb-3 float-right"
        style="z-index: 1"
        @click="copy($store.state.surveyStruct)"
      >
        Copiar
      </vs-button>

      <json-viewer
        :value="$store.state.surveyStruct"
        :expand-depth="7"
        copyable
      />
    </vs-popup>
  </div>
</template>

<script>
/* Components */
import SurveyStructure from '../../dashboard-components/survey-structure/SurveyStructure';
import PrintSurvey from '../../dashboard-components/survey-structure/PrintSurvey';
import ProjHist from '../../../widgets/chart-widgets/survey-hist/SurveyHist';
import TotalProjects from '../../../widgets/chart-widgets/total-projects/TotalProjects';
import SurveyResult from '@/views/dashboards/FanBox/Survey/SurveyResult';

/* Config */
import axios from 'axios';
import { configDomain, baseUrl } from '@/global';
import JsonViewer from 'vue-json-viewer';

/* Services */
import Survey from '@/services/survey';
import System from '@/services/system';

const surveyService = new Survey();
const systemService = new System();

/* Style */
import 'vue-json-viewer/style.css';

export default {
  name: 'SurveyDetails',
  components: {
    TotalProjects,
    ProjHist,
    SurveyStructure,
    PrintSurvey,
    JsonViewer,
    SurveyResult,
  },
  data: () => ({
    // Month Table
    surveyId: 0,
    surveyRef: 0,
    projectList: null,
    surveyTypes: null,
    getSurveys: null,
    surveyParams: [],
    currentSurveyParams: [],
    type_survey: null,
    surveyName: '',
    dataAcc: [],
    publishSurveyPopup: false,
    editParamsPopup: false,
    seriesAcc: [
      {
        name: 'Cadastros',
        data: [],
      },
    ],
    mensalSeries: [
      {
        name: 'Mês corrente',
        data: [],
      },
      {
        name: 'Mês passado',
        data: [],
      },
    ],
    histMonth: [
      {
        name: 'Concluídos no dia',
        type: 'column',
        data: [],
      },
      {
        name: 'Novos IDs no dia',
        type: 'column',
        data: [],
      },
    ],
    qtTotal: 0,
    totalIdCreated: 0,
    qtConcluded: 0,
    percConc: 0,
    qtPerc: '',
    printSurveyPopup: false,
    viewJsonPopup: false,
    phase: '',
    phaseLoaded: false,
    selected: 'DETALHES',
    chartCSV: '',
  }),
  computed: {
    cor2() {
      return this.$store.state.configBox.cor2;
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
    paramsTableData() {
      const setParams = this.surveyParams.map((param) => ({
        param,
        value: '',
      }));

      const data = setParams.map((obj) => {
        const hasValue = this.currentSurveyParams.find((o) => {
          return o.param === obj.param.param;
        });

        if (!hasValue) {
          return obj;
        } else {
          return {
            ...obj,
            value: hasValue.value,
          };
        }
      });

      return data;
    },
  },
  watch: {
    type_survey: function () {
      this.buscarSurveys();
    },
  },
  created() {
    var ret = this.$store.state.surveyStats;

    this.surveyName = ret.surveyInf.surveyNome;
    this.surveyId = ret.surveyInf.surveyId;
    this.surveyRef = ret.surveyInf.srvSructRef;
    this.projectID = ret.surveyInf.project;
    this.qtTotal = ret.totalSystem;
    this.totalIdCreated = ret.totalIdCreated;
    this.qtConcluded = ret.totalconcluded;
    this.percConc = ret.concludedPerc;
    this.qtPerc = ret.concludedPerc + '%';
    this.dataAcc = ret.accStats;

    var i;

    // acumulado
    for (i = 0; i < this.dataAcc.length; i++) {
      this.seriesAcc[0].data.push(this.dataAcc[i].qtDay);
    }

    // mens corrente
    for (i = 0; i < ret.dayStatsMonth.length; i++) {
      this.mensalSeries[0].data.push(ret.dayStatsMonth[i].qtDay);

      var dd = ret.dayStatsMonth[i].beginDate.substring(0, 2);
      var mm = ret.dayStatsMonth[i].beginDate.substring(3, 5);
      var yy = ret.dayStatsMonth[i].beginDate.substring(6, 10);
      var d3 = mm + '-' + dd + '-' + yy + ' GMT';
      var payload = { x: d3, y: ret.dayStatsMonth[i].qtDay };
      this.histMonth[0].data.push(payload);
    }

    for (i = 0; i < ret.idCreatedMonth.length; i++) {
      this.mensalSeries[0].data.push(ret.idCreatedMonth[i].qtDay);
      var ddd = ret.idCreatedMonth[i].beginDate.substring(0, 2);
      var mmm = ret.idCreatedMonth[i].beginDate.substring(3, 5);
      var yyy = ret.idCreatedMonth[i].beginDate.substring(6, 10);
      var dd3 = mmm + '-' + ddd + '-' + yyy + ' GMT';
      var payload2 = { x: dd3, y: ret.idCreatedMonth[i].qtDay };
      this.histMonth[1].data.push(payload2);
    }

    this.surveyId = this.$store.state.selectedSurvey;

    systemService
      .getProjectsByOwner(0, this.token)
      .then((ret) => {
        this.projectList = ret;

        surveyService
          .getSurveyTypes(this.token)
          .then((ret) => {
            this.surveyTypes = ret;
          });
      });
  },
  mounted() {
    this.surveyId = this.$store.state.selectedSurvey;

    this.loadSurveyPhase();

    this.getCSVAddress();

    surveyService.getSurveyOwnerById(this.surveyId, this.token)
      .then((res) => {
        this.$store.commit('SET_selectedOwner', res);
      });
  },
  methods: {
    async copy(json) {
      try {
        await navigator.clipboard.writeText(JSON.stringify(json));
        this.$vs.notify({
          title: 'Copiado',
          text: 'JSON copiado com sucesso',
          color: 'success',
          icon: 'check_box',
        });
      } catch ($e) {
        this.$vs.notify({
          title: 'Erro ao copiar',
          text: 'JSON não pôde ser copiado',
          color: 'danger',
          icon: 'check_box',
        });
      }
    },
    checkItemAccess(access) {
      if (!access) return true;
      return this.$store.state.loginData.accessValues.includes(access);
    },
    getCSVAddress() {
      surveyService
        .getSurveyStatsExportUrl(
          this.surveyId,
          this.token,
        )
        .then(
          (resp) => {
            this.chartCSV = resp;
          },
        );
    },
    getSurveyParams() {
      surveyService.getSurveyParams(this.token)
        .then((resp) => this.surveyParams = resp)
        .catch((err) => alert(err));
    },
    getSurveyParamsById() {
      surveyService.getSurveyParamsById(this.surveyId, this.token)
        .then((resp) => (this.currentSurveyParams = resp))
        .catch((err) => alert(err));
    },
    setSurveyParams(param, value) {
      surveyService
        .setSurveyParams(this.surveyId, param, value, this.token)
        .then(() => this.getSurveyParamsById());
    },
    openParamEditor() {
      this.getSurveyParamsById();

      this.getSurveyParams();

      this.editParamsPopup = true;
    },
    buscarSurveys() {
      surveyService
        .getSurveys(this.type_survey, 0, this.token)
        .then((ret) => this.getSurveys = ret);
    },
    saveSurvey() {
      this.$store.dispatch('set_isLoadingActive', true);

      this.$store.dispatch('saveSurveyStruct')
        .then(() => {
          this.$vs.notify({
            title: 'Sucesso',
            text: 'Questionário salvo',
            color: 'success',
            icon: 'check_box',
          });
        })
        .catch((err) => {
          this.$vs.notify({
            title: 'Erro ao salvar',
            text: `Tente salvar manualmente: (${err})`,
            color: 'danger',
            icon: 'warning'
          })
        })
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    imgpath(id) {
      return `${configDomain}/prj/${id}/logo.png`;
    },
    loadSurveyPhase() {
      this.$store.dispatch('set_isLoadingActive', true);

      const url = `${baseUrl}/SurveyPhase/${this.surveyId}`;
      const headers = { headers: { Authorization: `Bearer ${this.token}` } };


      axios.get(url, headers)
        .then((res) => this.phase = res.data.data)
        .catch((err) => alert(err))
        .finally(() => {
          this.$store.dispatch('set_isLoadingActive', false);
          this.phaseLoaded = true;
        });
    },
    setSurveyPhase() {
      this.$store.dispatch('set_isLoadingActive', true);

      const url = `${baseUrl}/SurveyPhase/${this.surveyId}/PROD`;
      const headers = { headers: { Authorization: `Bearer ${this.token}` } };

      axios.post(url, headers)
        .then(() => this.loadSurveyPhase())
        .catch((err) => alert(err))
        .finally(() => {
          this.$store.dispatch('set_isLoadingActive', false);
          this.publishSurveyPopup = false;
        });
    },
    isSelected(label) {
      return this.selected === label;
    },
  },
};
</script>

<style>
* :focus {
  outline: none !important;
}

.custom-icon {
  font-size: 13px;
  margin-left: 7px;
}

.params-table-td {
  width: 50%;
}
</style>
