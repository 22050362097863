<script>
export default {
  dayChart: {
    chartOptions: {
      labels: ['Day', 'Month'],
      dataLabels: {
        enabled: false
      },
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false,
            customIcons: [
              {
                icon: 'CSV',
                index: 5,
                title: 'Download CSV',
                class: 'custom-icon',
                click: function (__chart, __options, e) {
                  // Find Custom Icon Href
                  const href = e.target.parentElement.parentElement.parentElement.attributes['custom-icon-href'].value;

                  // Create Provisional <a> Element
                  const a = document.createElement('a');
                  a.href = href;
                  a.setAttribute('download', 'chart');
                  a.click();

                  // Destroy Element
                  a.remove();
                }
              }
            ],
          }
        }
      },
      grid: {
        padding: {
          left: 30,
          right: 30
        },
        borderColor: 'rgba(0,0,0,0.1)'
      },
      yaxis: [
        {
          seriesName: 'Usuários',
          show: true
        },
      ],
      tooltip: {
        shared: false,
        intersect: true,
        x: {
          show: true
        },
        theme: 'dark'
      },
      legend: {
        show: true
      },
      colors: ['#2962FF', '#4fc3f7'],
      plotOptions: {
        bar: {
          columnWidth: '25%'
        }
      },
      fill: {
        opacity: 1
      },
      stroke: {
        width: 0
      },
      xaxis: {
        type: 'datetime',
        //type: "string",
        categories: [],
        labels: {
          style: {
            cssClass: 'text-muted fill-color'
          }
        }
      }
    }
  },
  weekChart: {
    series: [60, 15],
    chartOptions: {
      labels: [],
      dataLabels: {
        enabled: false
      },
      grid: {
        padding: {
          left: 0,
          right: 0
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '95px'
          }
        }
      },
      stroke: {
        width: 0
      },
      legend: {
        show: false
      },
      colors: ['rgb(41, 97, 255)', 'rgb(218, 218, 218)'],
      tooltip: {
        fillSeriesColor: false,
        theme: 'dark'
      },
      responsive: [
        {
          breakpoint: 991.98,
          options: {
            chart: {
              height: 250,
              width: 250
            }
          }
        }
      ]
    }
  },
  monthChart: {
    series: [45, 15, 27, 18],
    chartOptions: {
      labels: ['Email', 'Website', 'Mobile', 'Other'],
      dataLabels: {
        enabled: false
      },
      grid: {
        padding: {
          left: 0,
          right: 0
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '80px'
          }
        }
      },
      stroke: {
        width: 0
      },
      legend: {
        show: true,
        position: 'bottom',
        onItemHover: {
          highlightDataSeries: true
        }
      },
      colors: ['#40c4ff', '#2961ff', '#ff821c', '#7e74fb'],
      tooltip: {
        fillSeriesColor: false,
        theme: 'dark'
      },
      responsive: [
        {
          breakpoint: 991.98,
          options: {
            chart: {
              height: 250
            }
          }
        }
      ]
    }
  }
};
</script>
