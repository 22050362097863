<template>
  <div>
    <!-- <vs-tabs alignment="left">
      <vs-tab label="Mês">
        <div class="px5"> -->
    <vue-apex-charts
      type="bar"
      height="300"
      :options="ShData.dayChart.chartOptions"
      :series="seriesMonth"
      :custom-icon-href="customIconHref"
    />
    <!-- </div>
      </vs-tab> -->
    <!--
          <vs-tab label="Semana">
              <div class="con-tab-ejemplo pt-5">
                  <div>
                      <vue-apex-charts type="line"
                                       height="400"
                                       :options="ShData.dayChart.chartOptions"
                                       :series="seriesWeek"></vue-apex-charts>
                  </div>
              </div>
          </vs-tab>
          <vs-tab label="Periodo">
              <div class="con-tab-ejemplo pt-5">
                  <vs-row vs-justify="left">

                      <datepicker :bootstrap-styling="true">
                          <div slot="beforeCalendarHeader" class="calender-header">
                              Início:
                          </div>
                      </datepicker>

                      <datepicker :bootstrap-styling="true">
                          <div slot="beforeCalendarHeader" class="calender-header">
                              Fim:
                          </div>
                      </datepicker>
                  </vs-row>

                <vs-row vs-justify="center">
                    <vs-col vs-lg="5" vs-xs="12" vs-sm="5">
                        <div>
                            <vue-apex-charts type="line"
                                            height="400"
                                            :options="ShData.dayChart.chartOptions"
                                            :series="seriesPeriodo">

                            </vue-apex-charts>
                        </div>
                    </vs-col>
                </vs-row>
              </div>
          </vs-tab>
          <vs-tab label="xxx">
              <div class="con-tab-ejemplo pt-5">
                  <vs-row vs-justify="center">
                      <vs-col vs-lg="5" vs-xs="12" vs-sm="5">
                          <div>
                              <vue-apex-charts type="donut"
                                               height="400"
                                               width="400"
                                               :options="ShData.weekChart.chartOptions"
                                               :series="ShData.weekChart.series"></vue-apex-charts>
                          </div>
                      </vs-col>
                  </vs-row>
              </div>
          </vs-tab>
          <vs-tab label="Distribuição">
              <div class="con-tab-ejemplo pt-5">
                  <div>
                      <vue-apex-charts type="donut"
                                       height="400"
                                       :options="ShData.monthChart.chartOptions"
                                       :series="seriesDist"></vue-apex-charts>
                  </div>
              </div>
          </vs-tab> -->
    <!-- </vs-tabs> -->
  </div>
</template>
<script>

import VueApexCharts from 'vue-apexcharts';
import ShData from './shData';
// import Datepicker from 'vuejs-datepicker';

export default {
  name: 'ProductSales',
  components: {
    VueApexCharts,
    // Datepicker
  },
  props: {
    seriesDist: Object,
    seriesMonth: Object,
    customIconHref: String,
  },
  data: () => ({
    title: 'ProductSales',
    dateFrom: Date,
    dateTo: Date,
    ShData: ShData,
  }),
};
</script>
