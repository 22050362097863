<template>
  <div>
    <vs-button
      class="mb-3 float-right"
      style="z-index: 1"
      @click="printSurvey()"
    >
      Imprimir
    </vs-button>
    <div id="print">
      <h1>{{ survey.surveyName }}</h1>
      ID do survey: {{ survey.surveyId }}<br><br>

      <span
        v-for="cap in survey.chapters"
        :key="cap.id"
      >
        <h3>
          Capítulo {{ cap.code }}: {{ cap.name }}
        </h3>

        <span
          v-for="req in cap.requirements"
          :key="req.id"
        >
          <h4 class="req ml-2">
            Tela {{ req.code }}: {{ req.text }}
          </h4>

          <span
            v-for="quest in req.questions"
            :key="quest.id"
          >
            <p class="quest ml-3 mb-0">
              Questão {{ quest.id }}: <i>{{ quest.text }}</i>
            </p>

            <ul
              v-if="quest.type === 3"
              class="ml-5"
            >

              <!--              <li v-for="item in getList(item)" :key="item.value">-->
              <li
                v-for="(item, i) in quest.options"
                :key="i"
              >
                {{ item.text }}
              </li>
            </ul>
          </span>
        </span>
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PrintSurvey',
  props: {
    survey: Object
  },
  methods: {
    printSurvey() {
      var printContents = document.getElementById('print').innerHTML;

      var winPrint = window.open('', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0');
      winPrint.document.body.innerHTML = printContents
      winPrint.document.body.style = 'font-family: "Anek Malayalam", sans-serif; font-size: 12px'
      let h4 = winPrint.document.querySelectorAll('.req')
      h4.forEach(el => {
        el.style = 'margin-left: 0.5rem'
      })
      let p = winPrint.document.querySelectorAll('.quest')
      p.forEach(el => {
        el.style = 'margin-left: 1rem; margin-bottom: 0px'
      })
      winPrint.document.close();
      winPrint.focus();
      winPrint.print();
      winPrint.close();
    }
  }
}
</script>

<style scoped>

</style>
