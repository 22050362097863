<template>
  <div>
    <vs-row>
      <vs-col
        vs-lg="8"
        vs-xs="12"
        vs-sm="8"
      >
        <h3 class="card-title">
          Visão geral
        </h3>
        <h5 class="card-subtitle mb-0 font-weight-normal text-muted">
          Dados de conclusão
        </h5>
        <vs-row class="mt-4">
          <vs-col vs-lg="3">
            <i
              class="mdi mdi-checkbox-blank-circle"
              style="color: rgb(255, 130, 28)"
            />
            <h3 class="mb-0">
              {{ qtTotal }}
            </h3>
            <span>Iniciados</span>
          </vs-col>
          <vs-col vs-lg="3">
            <i class="mdi mdi-checkbox-blank-circle text-warning" />
            <h3 class="mb-0">
              {{ qtConcluded }}
            </h3>
            <span>Concluídos</span>
          </vs-col>
          <vs-col vs-lg="3">
            <i
              class="mdi mdi-checkbox-blank-circle"
              style="color: rgb(30, 200, 30)"
            />
            <h3 class="mb-0">
              {{ percConcluded }}%
            </h3>
            <span>Taxa de conclusão</span>
          </vs-col>
          <vs-col vs-lg="3">
            <i class="mdi mdi-checkbox-blank-circle" />
            <h3 class="mb-0">
              {{ totalIdCreated }}
            </h3>
            <span>Novos IDs</span>
          </vs-col>
        </vs-row>
      </vs-col>
      <vs-col vs-lg="4">
        <div class="mt-4 text-center">
          <vue-apex-charts
            type="donut"
            height="150"
            :options="donutChart.chartOptions"
            :series="donutChart.series"
          />
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'TotalEarnings',
  components: {
    VueApexCharts,
  },
  props: {
    qtConcluded: { type: Number },
    percConcluded: { type: Number },
    qtTotal: { type: Number },
    totalIdCreated: { type: Number },
    seriesAcc: Object,
  },
  data: () => ({
    title: 'Total Projetos',
    donutChart: {
      series: [],
      chartOptions: {
        labels: ['Concluídos', 'Incompletos'],
        dataLabels: {
          enabled: false,
        },
        grid: {
          padding: {
            left: 0,
            right: 0,
          },
          borderColor: 'rgba(0,0,0,0.1)',
        },
        plotOptions: {
          pie: {
            donut: {
              size: '70px',
            },
          },
        },
        stroke: {
          width: 0,
        },
        legend: {
          show: false,
        },
        colors: [
          'rgb(64, 196, 255)',
          'rgb(255, 130, 28)',
          'rgb(126, 116, 251)',
          'rgb(41, 97, 255)',
        ],
        tooltip: {
          fillSeriesColor: false,
        },
      },
    },
  }),
  beforeMount() {
    this.donutChart.series.push(this.qtConcluded);
    this.donutChart.series.push(this.qtTotal - this.qtConcluded);
  },
};
</script>
